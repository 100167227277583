<template>
    <div class="services">
        <Navbar/>
        <div class="container-fluid h-100">
            <div class="row d-flex align-items-center h-100">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-lg-6 card-intro">
                            <div class="intro-wrapper">
                                <div class="h4-wrapper">
                                    <h3>Вложить деньги в свое будущее и не прогадать.</h3>
                                </div>
                                <p>
                                    Как выбрать высшее учебное заведение или языковые курсы, которые дадут реальный прорыв в карьере? Во Франции огромное количество учебных заведений, но далеко не все из них надежные. 
                                </p>
                                <p>
                                    Наше агентство специализируется на обучении в высших школах (бизнес-школы, инженерные, школы моды и дизайна..), а также подборе языковых и кулинарных курсов. Мы сами получали высшее образование во Франции и работаем только с проверенными учебными заведениями, имеющими высокую квалификацию и ежегодно попадающими в рейтинги. 
                                </p>
                                <p>     
                                    Наше агентство является официальным партнером представленных на сайте учебных заведений, поэтому мы поможем Вам с поступлением абсолютно бесплатно (подберем школу и программу, проверим досье, поможем с подготовкой к интервью, свяжемся с учебным заведением по всем возможным вопросам).
                                </p>
                                <p>
                                    Кроме этого, если Ваша цель - поступление в государственный вуз или иное учебное заведение во Франции, мы также предоставляем дополнительный платный пакет услуг.
                                </p>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 card-service">
                            <div class="services-wrapper">
                                <div class="title-wrapper">
                                    <h4>Наши услуги</h4>
                                </div>
                                <div class="navigation">
                                    <ul class="nav nav-pills">
                                        <li class="nav-item">
                                            <a ref="main" @click="category='main'" class="nav-link active" aria-current="page" href="#">Основные</a>
                                        </li>
                                        <li class="nav-item">
                                            <a ref="complement" @click="category='complement'" class="nav-link" href="#" >Дополнительные</a>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="category==='main'" class="category" id="main">
                                    <div class="h5-wrapper">
                                        <h5>Основные услуги</h5>
                                    </div>
                                    <div class="services-wraper">
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Подбор языковых курсов и подача документов в партнерскую школу
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">бесплатно</span>
                                            </div>
                                        </div>
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Помощь с подготовкой досье для поступления в партнерское учебное заведение -, тренировка прохождения интервью, сопровождение на всех этапах набора и подачи документов на визу
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">бесплатно</span>
                                            </div>
                                        </div>
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Помощь с подготовкой досье для поступления в партнерское учебное заведение -, тренировка прохождения интервью, сопровождение на всех этапах набора и подачи документов на визу
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">бесплатно</span>
                                            </div>
                                        </div>
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Консультация по выбору государственного вуза, поиску работы и т.д.
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">80 евро - 1 час</span>
                                            </div>
                                        </div>
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Проверка мотивационного письма, резюме (не в школу-партнер)
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">80 евро/1 документ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="category==='complement'" class="category" id="complement">
                                    <div class="h5-wrapper">
                                        <h5>Дополнительные услуги</h5>
                                    </div>

                                    <div class="services-wraper">
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Помощь с подбором резиденции из нашего каталога  
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">100 евро</span>
                                            </div>
                                        </div>

                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Полное сопровождение по поступлению в высшее учебное заведение (государственный вуз, бизнес-школа не из числа партнеров)   
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">950 евро</span>
                                            </div>
                                        </div>

                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Помощь в открытии бизнеса во Франции
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">по запросу</span>
                                            </div>
                                        </div>

                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Курс по поиску работы во Франции (11 уроков по 30-40 минут)
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">по запросу</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="category d-lg-none" style="margin-top:5rem">
                                    <div class="h5-wrapper">
                                        <h5>Дополнительные услуги</h5>
                                    </div>

                                    <div class="services-wraper">
                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Помощь с подбором резиденции из нашего каталога  
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">100 евро</span>
                                            </div>
                                        </div>

                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Полное сопровождение по поступлению в высшее учебное заведение (государственный вуз, бизнес-школа не из числа партнеров)   
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">950 евро</span>
                                            </div>
                                        </div>

                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Помощь в открытии бизнеса во Франции
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">по запросу</span>
                                            </div>
                                        </div>

                                        <div class="row service">
                                            <div class="col-12 col-lg-8">
                                                Курс по поиску работы во Франции (11 уроков по 30-40 минут)
                                            </div>
                                            <div class="col-12 col-lg-4 text-end">
                                                <span class="price">по запросу</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="category cta">
                                    <div class="h5-wrapper">
                                        <h5>Контакты</h5>
                                    </div>
                                    <div class="row service">
                                        <div class="col-12 col-lg-8">
                                            Если у Вас остались вопросы
                                        </div>
                                        <div class="col-12 col-lg-4 cta-wrapper">
                                            <a class="btn btn-light" href="http://t.me/tani.paris">Telegram</a>
                                        </div>
                                    </div>
                                    <div class="row service">
                                        <div class="col-12 col-lg-8">
                                            Также подписывайтесь на наш профиль Инстаграм, где ежедневно публикуется самая последняя информация.
                                        </div>
                                        <div class="col-12 col-lg-4 cta-wrapper">
                                            <a class="btn btn-light btn-insta" href="http://t.me/tani.paris">Instagram</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
export default {
    data() {
        return {
            category : 'main'
        }
    },
    components: {
        Navbar
    },
    mounted() {
        let navigationPills = document.querySelectorAll('.nav-link');
        navigationPills.forEach( navigationPill => {
            navigationPill.addEventListener('click', event => {
                navigationPills.forEach( navigationPill => {navigationPill.classList.toggle('active')});
            });
        })
    }
    
}
</script>